<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="设备节点"
      :paginations="{ pageSize: 20 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :top-operates="topOperates"
      selection
      :batch-operates="batchOperates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouProForm ref="importFormRef" title="导入设备节点" :is-dialog="true" :items="importFormItems" :on-ok="handleImport"/>
    <JuouProForm ref="batchEditFormRef" title="批量修改" :is-dialog="true" :items="batchEditFormItems" :on-ok="handleBatchEdit"/>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseUserStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref } from 'vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const importFormRef = ref<InstanceType<typeof JuouProForm>>();
const batchEditFormRef = ref<InstanceType<typeof JuouProForm>>();

const baseUserStore = useBaseUserStore();

const importFormItems: any[] = [
  {
    name: 'filePath',
    label: '文件',
    type: 'file',
    action: `${useApi().baseUrl}${useApi().uploadExcelUrl}`,
    headers: {
      'Authorization': baseUserStore.token,
    },
    method: 'post',
    fieldName: 'files',
    multiple: false,
    placeholder: '请先下载模版文件，录入后上传',
    onFailed() {
      ElMessage.error('请选择正确的文件格式');
    },
    rule: [{ required: true }],
  },
];

const batchEditFormItems = ref<any[]>([]);

const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(async() => {
  const res = (await useApi().nodeHostApi.listNodeHostnodeHost({ conditions: {} }));
  const options = res.data.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));


  columns.value = [
    {
      name: 'clientId',
      label: '序列号',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'nodeHostId',
      label: '节点地址',
      type: 'searchSelect',
      options,
      isCondition: true,
      rule: [{ required: true }],
    },

  ];
  batchEditFormItems.value = [

    {
      name: 'nodeHostId',
      label: '节点地址',
      type: 'searchSelect',
      options,
      isCondition: true,
      rule: [{ required: true }],
    },
  ];
});


const operates: any[] = [];

const topOperates: any[] = [
  {
    name: '获取模版',
    key: 'getTpt',
    handler: useBaseAsync(() => {
      window.open('/api/template-device-nodehost.xlsx');
    }),
  },
  {
    name: '导入',
    key: 'import',
    handler: useBaseAsync(() => {
      importFormRef.value?.reset();
      importFormRef.value?.showDialog();
    }),
  },
];

const currentBatchChecks = ref<any[]>([]);

const batchOperates: any[] = [
  {
    name: '批量删除',
    key: 'batchDel',
    handler: useBaseAsync(async(data: any) => {
      await ElMessageBox.alert('是否确定删除', '批量删除', {
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      await useApi().deviceNodeApi.batchDelDeviceNodedeviceNode({
        deviceNodeIds: data.map((item: any) => item.id),
      });

      ElMessage.success('删除成功');
      tableRef.value?.refresh();
    }),
  },
  {
    name: '批量修改',
    key: 'batchEdit',
    handler: useBaseAsync((data: any) => {
      currentBatchChecks.value = data;
      batchEditFormRef.value?.reset();
      batchEditFormRef.value?.showDialog();
    }),
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().deviceNodeApi.pageDeviceNodedeviceNode({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },
    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.clientId})操作，请谨慎操作`;

const addFun = useBaseAsync(async(data: any) => {
  await useApi().deviceNodeApi.addDeviceNodedeviceNode(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().deviceNodeApi.updateDeviceNodedeviceNode(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().deviceNodeApi.delDeviceNodedeviceNode(data.id);
  ElMessage.success('删除成功');
});

const handleImport = useBaseAsync(async(data: any) => {
  await useApi().deviceNodeApi.batchAddDeviceNodedeviceNode({
    filePath: data.filePath,
  });
  ElMessage.success('导入成功');
  tableRef.value?.refresh();
});


const handleBatchEdit = useBaseAsync(async(data: any) => {
  await useApi().deviceNodeApi.batchUpdateDeviceNodeHostdeviceNode({
    deviceNodeIds: currentBatchChecks.value?.map((item) => item.id) || [],
    nodeHostId: data.nodeHostId,
  });
  ElMessage.success('修改成功');
  tableRef.value?.refresh();
});


init();

</script>
<style lang="scss" scoped>
</style>
