<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="设备管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouDangerDialog
      ref="upateDialog"
      title="更新警告"
      opt-name="更新"
      :on-ok="handleUpdateAction"
      :format-dangder-delete-info="updateDangderInfo"
    />
    <JuouProForm ref="setModFormRef" title="设置模式" :is-dialog="true" :items="setModItems" :on-ok="handleSetMod"/>
    <JuouProForm ref="setRemarkFormRef" title="设置备注" :is-dialog="true" :items="setRemarkItems" :on-ok="handleSetRemark"/>
    <binduser ref="binduserRef" v-if="canBindUser && currentRow" :client-id="currentRow?.clientId" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import binduser from './binduser.vue';
import { AxiosError } from 'axios';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const binduserRef = ref<InstanceType<typeof binduser>>();
const baseOperateStore = useBaseOperateStore();

const setModItems: JuouProTableColumn[] = [
  {
    name: 'mod',
    label: '设备模式',
    type: 'searchSelect',
    options: [
      {
        label: '常开',
        value: 'open',
      },
      {
        label: '常闭',
        value: 'close',
      },
      {
        label: '点动',
        value: 'control',
      },
    ],
    isCondition: true,
    rule: [{ required: true }],
  },
];
const setRemarkItems: JuouProTableColumn[] = [
  {
    name: 'remark',
    label: '设备备注',
    type: 'string',
    rule: [{ required: true }],
  },
];

const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'remark',
      label: '设备备注',
      type: 'string',
      isCondition: true,
      order: 10,
    },
    {
      name: 'clientId',
      label: '序列号',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'iccid',
      label: '4G卡号',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],

      manualRefresh: (row) => {
        row.iccid = '加载中...';
        useApi()
          .mqttApi.getICCIDmqtt({
            clientId: row.clientId,
          }, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            row.iccid = statusRes.data.data;
          })
          .catch(() => {
            row.iccid = '';
          });
      },
    },
    {
      name: 'version',
      label: '版本',
      isCondition: true,
      type: 'string',
      hideCreate: true,

      manualRefresh: (row) => {
        row.version = '加载中...';
        useApi()
          .mqttApi.getVersionmqtt({
            clientId: row.clientId,
          }, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            row.version = statusRes.data.data;
          })
          .catch(() => {
            row.version = '';
          });
      },
    },
    {
      name: 'isOnline',
      label: '是否在线',
      type: 'select',
      options: [
        {
          label: '在线',
          value: true,
        },
        {
          label: '离线',
          value: false,
        },
      ],
      isCondition: true,
      hideCreate: true,

      manualRefresh: (row) => {
        row.isOnline = '加载中...';
        useApi()
          .deviceApi.getStatusdevice(row.clientId, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            row.isOnline = statusRes.data.data;
          })
          .catch(() => {
            row.isOnline = '-';
          });
      },
    },
    {
      name: 'mod',
      label: '设备模式',
      type: 'searchSelect',
      options: [
        {
          label: '常开',
          value: 'open',
        },
        {
          label: '常闭',
          value: 'close',
        },
        {
          label: '点动',
          value: 'control',
        },
      ],
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'swtichStatus',
      label: '开关状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      manualRefresh: (row) => {
        row.swtichStatus = '加载中...';
        useApi()
          .mqttApi.switchStatusmqtt(row.clientId, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            row.swtichStatus = statusRes.data.data === '1' ? '打开' : '关闭';
          })
          .catch(() => {
            row.swtichStatus = '-';
          });
      },
    },

    {
      name: 'rssi',
      label: '信号质量',
      type: 'string',
      rule: [{ required: true }],

      manualRefresh: (row) => {
        row.rssi = '加载中...';
        useApi()
          .mqttApi.getRSSImqtt({
            clientId: row.clientId,
          }, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            const val = Number(statusRes.data.data);
            row.rssi = Number.isNaN(val) ? '-' : (115 - Math.abs(val));
          })
          .catch(() => {
            row.rssi = '';
          });
      },
    },
    {
      name: 'createTime',
      label: '添加日期',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },

  ];
});

const handleUpdateAction = useBaseAsync(async(data: any) => {
  await useApi()
    .mqttApi.upgrademqtt({
      clientId: data.clientId,
    }, {
      headers: {
        'custom-error': 'true',
      },
    })
    .then((res) => {
      ElMessage.success('下发更新成功, 请等60秒后刷新列表');
    })
    .catch(() => {
      ElMessage.error('下发更新失败');
    });
});
const upateDialog = ref<InstanceType<typeof JuouDangerDialog>>();
const setModFormRef = ref<InstanceType<typeof JuouProForm>>();
const setRemarkFormRef = ref<InstanceType<typeof JuouProForm>>();

const currentRow = ref<any>();

const operates = [

  {
    key: 'setRemark',
    name: '设置备注',
    handler: useBaseAsync((row: any) => {
      currentRow.value = row;
      setRemarkFormRef.value?.setOneFormState('remark', row.remark);
      setRemarkFormRef.value?.showDialog();
    }),
  },


  {
    key: 'deviceUpdate',
    name: '更新版本',
    handler: (row: any) => {
      if (!/\d\.\d\.\d/u.test(row.version)) {
        ElMessage.warning('未获取到当前版本，请检查设备类型和设备状态，刷新列表重试');
        return;
      }
      upateDialog.value?.showDialog(row);
    },
  },

  {
    key: 'inching',
    name: '点动',
    handler: async(row: any) => {
      await useApi().mqttApi.inchingmqtt({
        clientId: row.clientId,
      });
      ElMessage.success('成功');
      row.swtichStatus = '加载中...';
      useApi()
        .mqttApi.switchStatusmqtt(row.clientId, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((statusRes) => {
          row.swtichStatus = statusRes.data.data === '1' ? '打开' : '关闭';
        })
        .catch(() => {
          row.swtichStatus = '-';
        });
    },
  },

  {
    key: 'setMod',
    name: '设置模式',
    handler: useBaseAsync((row: any) => {
      currentRow.value = row;
      setModFormRef.value?.setOneFormState('mod', row.mod);
      setModFormRef.value?.showDialog();
    }),
  },

  {
    key: 'manualDisconnect',
    name: '踢下线',
    handler: useBaseAsync(async(row: any) => {
      await useApi().deviceApi.manualDisconnectdevice(row.clientId);
      ElMessage.success('成功');
      row.isOnline = '加载中...';
      setTimeout(() => {
        useApi()
          .deviceApi.getStatusdevice(row.clientId, {
            headers: {
              'custom-error': 'true',
            },
          })
          .then((statusRes) => {
            row.isOnline = statusRes.data.data;
          })
          .catch(() => {
            row.isOnline = '-';
          });
      }, 1000);
    }),
  },

];


const canBindUser = baseOperateStore.operates?.find((item) => item.key === 'binduser');

if (canBindUser) {
  operates.push({
    key: 'binduser',
    name: '设备用户',
    handler: useBaseAsync((row: any) => {
      currentRow.value = row;
      binduserRef.value?.show();
    }),
  });
}


const loading = ref(false);

const handleSetMod = useBaseAsync(async(data: any) => {
  if (!currentRow.value.clientId) {
    ElMessage.error('请选择一个设备');
    return;
  }
  try {
    await useApi().mqttApi.setModmqtt({
      clientId: currentRow.value.clientId,
      mod: data.mod,
    });
    ElMessage.success('设置模式成功');
  } catch (error) {
    if ((error as any)?.response?.status !== 501) {
      throw error;
    }
  }
  tableRef.value?.refresh();
});

const handleSetRemark = useBaseAsync(async(data: any) => {
  if (!currentRow.value.clientId) {
    ElMessage.error('请选择一个设备');
    return;
  }
  await useApi().deviceApi.setDeviceRemarkdevice({
    clientId: currentRow.value.clientId,
    remark: data.remark,
  });
  ElMessage.success('设置模式成功');
  tableRef.value?.refresh();
});

const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().deviceApi.pageDevicedevice({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },
    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableData.data = tableData.data.map((item) => {
      const swtichStatusRef = ref('加载中...');
      const rssiRef = ref('加载中...');
      useApi()
        .mqttApi.switchStatusmqtt(item.clientId, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((statusRes) => {
          swtichStatusRef.value = statusRes.data.data === '1' ? '打开' : '关闭';
        })
        .catch(() => {
          swtichStatusRef.value = '-';
        });
      useApi()
        .mqttApi.getRSSImqtt({
          clientId: item.clientId,
        }, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((statusRes) => {
          const val = Number(statusRes.data.data);
          rssiRef.value = Number.isNaN(val) ? '-' : (115 - Math.abs(val)).toString();
        })
        .catch(() => {
          rssiRef.value = '-';
        });
      return {
        ...item,
        swtichStatus: swtichStatusRef,
        rssi: rssiRef,
      };
    });
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.clientId})操作，请谨慎操作`;

const updateDangderInfo = (row: any) => `您正在进行更新(${row.clientId})操作，请保证设备通电稳定在线，下发更新后，请等待60秒刷新查看版本`;


init();

</script>
<style lang="scss" scoped>
</style>
