<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="版本管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :top-operates="topOperates"
      :codition-init-values="conditionsInitValue"
    />
    <JuouProForm ref="testFormRef" title="测试获取版本列表" :is-dialog="true" :items="testItems" :on-ok="handleTestVersion"/>

  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseUserStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref } from 'vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const testFormRef = ref<InstanceType<typeof JuouProForm>>();


const addInitValue = {

};
const conditionsInitValue = {
};
const baseUserStore = useBaseUserStore();

const testItems: JuouProTableColumn[] = [
  {
    name: 'clientId',
    label: '序列号',
    type: 'string',
    rule: [{ required: true }],
  },
  {
    name: 'type',
    label: '设备类型',
    type: 'string',
    rule: [{ required: true }],
  },
  {
    name: 'oldVersion',
    label: '当前版本号',
    type: 'string',
    rule: [{ required: true }],
  },
];
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'type',
      label: '版本类型',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'version',
      label: '版本号',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'files',
      label: '版本文件',
      type: 'file',
      action: `${useApi().baseUrl}${useApi().uploadZipUrl}`,
      headers: {
        'Authorization': baseUserStore.token,
      },
      method: 'post',
      fieldName: 'files',
      multiple: false,
      placeholder: '请选择打包为zip的压缩文件',
      onFailed() {
        ElMessage.error('请选择正确的文件格式');
      },
      rule: [{ required: true }],
    },
    {
      name: 'createTime',
      label: '上传时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },

  ];
});


const operates: any[] = [];
const topOperates: any[] = [
  {
    name: '测试获取版本列表',
    key: 'test',
    handler: useBaseAsync(() => {
      testFormRef.value?.setFormState({
        clientId: 'TestClientId',
        type: 'wifi',
        oldVersion: '1.0.8',
      });
      testFormRef.value?.showDialog();
    }),
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().versionApi.pageVersionversion({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },
    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;

const addFun = useBaseAsync(async(data: any) => {
  await useApi().versionApi.addVersionversion(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().versionApi.updateVersionversion(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().versionApi.delVersionversion(data.id);
  ElMessage.success('删除成功');
});

const handleTestVersion = useBaseAsync(async(data: any) => {
  const res = await useApi().versionApi.getUpdateListversion(data.clientId, data.type, data.oldVersion);
  ElMessageBox.alert(res?.data?.toString()?.replaceAll(',', '</br>') || '', '需要更新的版本文件列表', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: '确定',
    dangerouslyUseHTMLString: true,
  });
});


init();

</script>
<style lang="scss" scoped>
</style>
