import {
  Configuration,
  DeviceApi,
  NodeHostApi,
  DeviceNodeApi,
  MqttApi,
  UserApi,
  VersionApi,
} from '@/api';
import { axios } from 'juou-managebase-ui';

interface BaseApi {
  baseUrl: string;
  uploadZipUrl: string;
  uploadExcelUrl: string;
  deviceApi: DeviceApi;
  nodeHostApi: NodeHostApi;
  deviceNodeApi: DeviceNodeApi;
  mqttApi: MqttApi;
  userApi: UserApi;
  versionApi: VersionApi;
}

const api: BaseApi = {

} as any;

export const initApi = (basePath: string) => {
  const apiConfig = new Configuration({
    basePath,
  });
  api.baseUrl = basePath;
  api.uploadZipUrl = '/upload/zip';
  api.uploadExcelUrl = '/upload/excel';
  api.deviceApi = new DeviceApi(apiConfig, basePath, axios);
  api.nodeHostApi = new NodeHostApi(apiConfig, basePath, axios);
  api.deviceNodeApi = new DeviceNodeApi(apiConfig, basePath, axios);
  api.mqttApi = new MqttApi(apiConfig, basePath, axios);
  api.userApi = new UserApi(apiConfig, basePath, axios);
  api.versionApi = new VersionApi(apiConfig, basePath, axios);
};

export const useApi = () => api;

