<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="设备状态日志"
      :paginations="{ pageSize: 20 }"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'createTime',
      label: '时间',
      type: 'string',
    },
    {
      name: 'clientId',
      label: '序列号',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'message',
      label: '日志信息',
      type: 'string',
    },

  ];
});


const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().deviceApi.pageDeviceStatusLogdevice({
      clientId: filter.conditions.clientId || undefined,
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },
    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data?.map((item: any) => ({
        ...item,
        clientId: item.message.match(/.*'(?<clientId>.+)'/u)?.groups?.clientId || '未知序列号',
      })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

init();

</script>
<style lang="scss" scoped>
</style>
