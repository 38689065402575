<template>
  <el-dialog v-model="visible" title="设备绑定用户">
    <JuouProTable
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      add-text="绑定"
      delete-text="解绑"
      :loading="loading"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();

const visible = ref(false);

const props = defineProps<{
  clientId: string;
}>();

const addInitValue = {

};
const conditionsInitValue = {
};

defineExpose({
  show: () => {
    visible.value = true;
  },
});


const init = useBaseAsync(async() => {
  const res = (await useApi().userApi.getAllUseruser());
  const options = res.data.data?.map((item: any) => ({
    value: item.id,
    label: item.username,
  }));

  columns.value = [
    {
      name: 'id',
      label: '用户名',
      type: 'searchSelect',
      options,
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    if (!props.clientId) {
      return {
        total: 0,
        data: [],
      };
    }
    loading.value = true;
    const res = await useApi().deviceApi.listDeviceUserdevice(props.clientId);
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data?.map((item: any) => ({ id: item })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});


const addFun = useBaseAsync(async(data: any) => {
  if (!props.clientId) {
    return;
  }
  await useApi().deviceApi.bindUserDevicedevice({
    clientId: props.clientId,
    userId: data.id,
  });
  ElMessage.success('绑定成功');
});


const deleteFun = useBaseAsync(async(data: any) => {
  if (!props.clientId) {
    return;
  }
  await useApi().deviceApi.unbindUserDevicedevice({
    clientId: props.clientId,
    userId: data.id,
  });
  ElMessage.success('解绑成功');
});


init();

</script>
<style lang="scss" scoped>
</style>
