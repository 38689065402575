import { createApp } from 'vue';
import { JuouManageBaseUI, JuouManageBaseUIApp, JuouComponentsMap } from 'juou-managebase-ui';
import 'juou-managebase-ui/lib/lib.css';
// import './style/global.scss';
import { initApi } from './hooks/useApi';

const allComponents: JuouComponentsMap = {};
const viewRequireCnt = require.context('./views', true, /index\.vue$/u);
viewRequireCnt.keys().forEach((key) => {
  const dirname = key.split('/').at(-2);
  console.log(dirname);
  allComponents[dirname!] = () => viewRequireCnt(key).default || viewRequireCnt(key);
});

initApi('/api');

createApp(JuouManageBaseUIApp)
  .use(JuouManageBaseUI, {
    siteName: '单路通断器管理后台',
    baseApiPath: '/api',
    viewMap: allComponents,
    description: '单路通断器管理后台',
    logo: '/logo.png',
  })
  .mount('#app');
